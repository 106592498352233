<template>
  <div class="offerSlider">
    <ClientOnly>
      <VueSlickCarousel v-bind="settings">
        <div
          class="cms-block-content"
          v-for="(slide, index) in cmsSpacialOffers.slides"
          :key="index"
        >
          <b-link
            :href="slide.link"
            :target="getTarget(slide.link)"
            :rel="getRel(slide.link)"
            ><div
              class="offer-content-box-wrap"
              :style="`background-image: url('` + slide.media.url + `');`"
            >
              <div v-html="slide.description"></div>
            </div>
          </b-link>
        </div>
      </VueSlickCarousel>
    </ClientOnly>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";
import VueSlickCarousel from "vue-slick-carousel";

// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "SpecialOfferSlider",
  components: {
    ClientOnly,
    VueSlickCarousel,
  },
  data: () => ({
    settings: {
      arrows: false,
      dots: false,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            centerMode: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            autoplay: true,
            speed: 5000,
          },
        },
      ],
    },
  }),
  computed: {
    cmsSpacialOffers() {
      return this.$store.getters[`sliders/getSliderByIdentifier`](
        "home_page_special_offers_slider"
      );
    },
  },
  methods: {
    getTarget(link) {
      if (link && link.substring(0, 4) == "http") {
        return "_blank";
      } else {
        return "_self";
      }
    },
    getRel(link) {
      if (link && link.substring(0, 4) == "http") {
        return "noopener noreferrer";
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
</style>