<template>
  <b-col cols="12" lg="3" sm="12" class="tab-order-2">
    <div class="home-banner-categories">
      <div class="home-categories-mob-title">{{ $t("categories") }}</div>
      <section class="image-gallery">
        <div class="row flex-column" v-if="typeof images == 'object'">
          <div
            class="col-12 img-grid"
            v-for="image of images.slides"
            :key="image.id"
          >
            <b-link
              :to="image.link"
              v-if="image.link && image.link.substring(0, 4) !== 'http'"
            >
              <div class="home-category-banner">
                <div class="home-category-image">
                  <img :src="image.media.url" alt="" />
                </div>
                <div class="home-category-title">
                  {{ image.title }}
                  <ClientOnly>
                    <i class="fa fa-chevron-right"></i>
                  </ClientOnly>
                </div>
              </div>
            </b-link>
            <b-link
              :href="image.link"
              v-if="image.link && image.link.substring(0, 4) === 'http'"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="home-category-banner">
                <div class="home-category-image">
                  <img :src="image.media.url" alt="" />
                </div>
                <div class="home-category-title">
                  {{ image.title }}
                  <ClientOnly>
                    <i class="fa fa-chevron-right"></i>
                  </ClientOnly>
                </div>
              </div>
            </b-link>
            <b-link to="" v-if="!image.link">
              <div class="home-category-banner">
                <div class="home-category-image">
                  <img :src="image.media.url" alt="" />
                </div>
                <div class="home-category-title">
                  {{ image.title }}
                  <ClientOnly>
                    <i class="fa fa-chevron-right"></i>
                  </ClientOnly>
                </div>
              </div>
            </b-link>
          </div>
        </div>
      </section>
    </div>
  </b-col>
</template>

<script>
import ClientOnly from "vue-client-only";

export default {
  name: "ImageGallery",
  components: { ClientOnly },
  computed: {
    images() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "home_gallery"
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
