<template>
  <div>
    <!-- HomePage Top -->
    <section class="pb-5">
      <b-container>
        <b-row>
          <ImageGallery />
          <HeroBanner />
        </b-row>
      </b-container>
    </section>

    <!-- Offers & Advertisement -->
    <section class="offers-section py-5">
      <div class="container">
        <SpecialOfferSlider />
        <HomeAdvertisement />
      </div>
    </section>

    <!-- Toppers of the week -->
    <div class="top-week">
      <b-container>
        <h2 class="section-title">
          Toppers van de week
          <b-link to="/ons-assortiment">({{ $t("all_category") }})</b-link>
        </h2>
        <ClientOnly>
          <TopProductsCarousel />
        </ClientOnly>
      </b-container>
    </div>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";

import HeroBanner from "@/base/core/components/home/HeroBanner";
import ImageGallery from "@/base/core/components/home/ImageGallery";
import SpecialOfferSlider from "@/base/core/components/home/SpecialOfferSlider";
import TopProductsCarousel from "@/base/core/components/home/TopProductsCarousel";
import HomeAdvertisement from "@/base/core/components/home/HomeAdvertisement";

export default {
  name: "HomePage",
  components: {
    HeroBanner,
    ImageGallery,
    TopProductsCarousel,
    SpecialOfferSlider,
    ClientOnly,
    HomeAdvertisement,
  },
  computed: {
    cmsDefaultPageContent() {
      return this.$store.state.cmsPage.default;
    },
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "title",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_title ??
              this.cmsDefaultPageContent.title ??
              ""
            : "",
        },
        {
          name: "keywords",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_keywords ?? ""
            : "",
        },
        {
          name: "description",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_description ?? ""
            : "",
        },
      ],
      title: this.cmsDefaultPageContent
        ? this.cmsDefaultPageContent.meta_title ??
          this.cmsDefaultPageContent.title ??
          ""
        : "",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/base/core/views/home/home.scss";
</style>
