<template>
  <div class="branding-section">
    <div class="row" v-if="typeof images == 'object'">
      <div
        class="col-12 col-sm-6 mb-4 mb-sm-0"
        v-for="image of images.slides"
        :key="image.id"
      >
        <b-link
          :to="image.link"
          v-if="image.link && image.link.substring(0, 4) !== 'http'"
          ><img :src="image.media.url" alt=""
        /></b-link>
        <b-link
          :href="image.link"
          v-if="image.link && image.link.substring(0, 4) === 'http'"
          rel="noreferrer noopener"
          target="_blank"
          ><img :src="image.media.url" alt=""
        /></b-link>
        <b-link to="" v-if="!image.link"
          ><img :src="image.media.url" alt=""
        /></b-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeAdvertisement",
  computed: {
    images() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "home_2_blocks"
      );
    },
  },
};
</script>

<style>
</style>
