<template>
  <b-col col cols="12" lg="9" sm="12" class="tab-order-1 mb-lg-0 mb-sm-4 mb-4">
    <div class="home-banner-slider" v-if="typeof slider != 'undefined'">
      <b-carousel
        id="carousel-1"
        :interval="5000"
        indicators
        img-width="1920"
        img-height="695"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
        class="hero-banner-carousel"
      >
        <b-link
          :href="bSlide.link"
          v-for="(bSlide, index) in slider.slides"
          :key="index"
          :target="getTarget(bSlide.link)"
          :rel="getRel(bSlide.link)"
        >
          <b-carousel-slide
            :style="{ backgroundImage: `url(${bSlide.media.url})` }"
          >
          </b-carousel-slide>
        </b-link>
      </b-carousel>
    </div>
  </b-col>
</template>

<script>
import { isMobile, isTablet, isBrowser } from "mobile-device-detect";
import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "HeroBanner",
  components: {},
  data: () => ({
    slide: 0,
    sliding: false,
  }),
  computed: {
    slider() {
      /*
      let slider = this.$store.getters["sliders/getSliderByIdentifier"]("home");

      if (slider.slides != null) {
        slider.slides.forEach((element) => {
          if (this.mobileCheck == "browser") {
            element.optImage = element.media.url;
          } else if (this.mobileCheck == "tablet") {
            element.optImage = element.media.smaller;
          } else {
            element.optImage = element.media.smallest;
          }
        });
      }

      return slider;
      */
      return this.$store.getters["sliders/getSliderByIdentifier"]("home");
    },
    mobileCheck() {
      if (isBrowser) {
        return "browser";
      }
      if (isMobile && isTablet) {
        return "tablet";
      }
      return "mobile";
    },
  },
  mounted() {
    Logger.debug("slides", "slider", this.slider)();
    Logger.debug("isTablet", "slider", isTablet)();
    Logger.debug("isBrowser", "slider", isBrowser)();
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    getTarget(link) {
      if (link && link.substring(0, 4) == "http") {
        return "_blank";
      } else {
        return "_self";
      }
    },
    getRel(link) {
      if (link && link.substring(0, 4) == "http") {
        return "noopener noreferrer";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss"></style>
